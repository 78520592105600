import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ComposeMessageComponent } from './compose-message.component';
import { PageNotFoundComponent } from './not-found.component';
import { RegisterComponent } from './register.component';
import { CanDeactivateGuard } from './can-deactivate-guard.service';
import { AuthGuard } from './auth-guard.service';
import { SelectivePreloadingStrategy } from './selective-preloading-strategy';
import { HomeComponent } from './public/home.component';
import { UnauthorisedComponent } from './unauthorised.component';
import {LoginComponent} from './login.component';


const appRoutes: Routes = [
  {
    path: "contact",
    component: ComposeMessageComponent,
    outlet: "popup"
  },
  {
    path: "admin",
    loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {

    path: 'customer',
    loadChildren: () =>
      import('./customer/customer.module').then(m => m.CustomerModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  { path: 'register', component: RegisterComponent },
  { path: 'code', component: LoginComponent },
  { path: 'unauthorised', component: UnauthorisedComponent },

  // {
  //   path: '',
  //   loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  //   canLoad: [AuthGuard],
  //   canActivate: [AuthGuard]
  // },
  { path: "", component: HomeComponent },
  { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
    preloadingStrategy: SelectivePreloadingStrategy,
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule],
  providers: [CanDeactivateGuard, SelectivePreloadingStrategy]
})
export class AppRoutingModule {}
