import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CurrentPetService {
    constructor() {}
    private currentPetObservable = new Subject<number>();

    // Observable string streams
    petId$ = this.currentPetObservable.asObservable();

  updateCurrentPet(petId: number) {
    console.log(petId);
    this.currentPetObservable.next(petId);
  }
}
