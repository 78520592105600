import {
  ModuleWithProviders, NgModule,
  Optional, SkipSelf }       from '@angular/core';
import { CommonModule }      from '@angular/common';
import { DataService }       from './data.service';
import { AddressService }       from './address.service';
import { UserService }       from './user.service';
import { ImageUploadService } from '../shared/file-upload/image-upload.service';
import { CurrentPetService } from '../customer/pets/current-pet.service';

@NgModule({
  imports:      [ CommonModule ],
  declarations: [  ],
  exports:      [  ],
  providers:    [ DataService, UserService, ImageUploadService, CurrentPetService, AddressService]
})
export class CoreModule {
}
