import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  CanLoad, Route
} from '@angular/router';
import { CustomAuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: CustomAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {

    // const loggedIn = localStorage.getItem('loggedIn');
    if (this.authService.loggedIn.getValue() === true) {
      if (url.indexOf('superuser') > 0) {
        // All admin urls appear under admin path
        if (this.authService.isOwner.getValue() === false) {

          this.router.navigate(['/unauthorised']);
          return false;
        }
      }
        if (url.indexOf('admin') > 0) {
          // All admin urls appear under admin path
          if (this.authService.isAdmin.getValue() === false) {
            this.router.navigate(['/unauthorised']);
            return false;
          }
      }
      return true;
    }

    // Store the attempted URL for redirecting
    this.authService.redirectUrl = url;
    // Navigate to the login page with extras
    this.router.navigate(['/login']); // , navigationExtras);
    return false;
  }
}
