import { Injectable } from '@angular/core';
import { Observable ,  of ,  BehaviorSubject } from 'rxjs';
import { DataService } from './core/data.service';
import { AuthorisationDetails } from './core/AuthorisationDetails';
import {NavigationExtras, Router} from '@angular/router';

@Injectable()
export class CustomAuthService {

  public loggedIn = new BehaviorSubject<boolean>(false);
  loggedInStream$ = this.loggedIn.asObservable();

  public isAdmin = new BehaviorSubject<boolean>(false);
  isAdminStream$ = this.isAdmin.asObservable();

  public isOwner = new BehaviorSubject<boolean>(false);
  isOwnerStream$ = this.isOwner.asObservable();

  public kennelSid = new BehaviorSubject<number>(null);
  kennelSidStream$ = this.kennelSid.asObservable();

  public userName = new BehaviorSubject<string>(null);
  userNameStream$ = this.userName.asObservable();

  public userPic = new BehaviorSubject<string>(null);
  userPicStream$ = this.userPic.asObservable();

  public bookingData = null;
  authDetails: AuthorisationDetails;

  // store the URL so we can redirect after logging in
  redirectUrl: string;


  constructor (
      private dataService: DataService, private router: Router) {
  }

  isLoggedIn(): boolean {
    return this.loggedIn.getValue();
  }

  getUserSid(): number {
    return this.authDetails.sid;
  }


  getCustomerSid(): number {
    return this.authDetails && this.authDetails.customerSid ? this.authDetails.customerSid : null;
  }

  loginWithAuthorities(authDetails: AuthorisationDetails): void {
    this.authDetails = authDetails;
    this.isAdmin.next(authDetails.isAdmin);
    this.isOwner.next(authDetails.isOwner);
    this.kennelSid.next(authDetails.kennelSid);
    this.userName.next(authDetails.name);
    this.userPic.next(authDetails.pictureURL);
    this.loggedIn.next(true);
    if (this.redirectUrl != null) {
        // Set our navigation extras object
        // that passes on our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParamsHandling: 'preserve',
            preserveFragment: true
        };
        this.router.navigate([this.redirectUrl], navigationExtras);
    }
  }

  login(): Observable<boolean> {
    this.loggedIn.next(true);
    return of(true); // .do(val => this.loggedIn.next(true));
  }

  logout(): void {
      this.dataService.callPut('authenticate/logout', '').subscribe(
          (details) => {
              console.log('Logged Out: ' + details);
          },
          (error) => {
              // console.log('Failed to logout: ' + JSON.parse(error._body).message);
              console.log('Failed to logout: ');
          });
      this.loggedIn.next(false);
      this.isAdmin.next(false);
      this.isOwner.next(false);
      localStorage.removeItem('cognito');
  }

  sendToRestApiMethod(token: string): void {
    // this.dataService.callPostNoAuth('authenticate', JSON.stringify(token)).subscribe(
    this.dataService.callGetAuthenticate('authenticate', JSON.stringify(token)).subscribe(
        (details) => {
          this.loginWithAuthorities(details);
        },
        (error) => {
          console.log('Failed to authenticate: ' + JSON.parse(error._body).message); });
  }

  updateBookingData(data): void {
    this.bookingData = data;
    if (data) {
      localStorage.setItem('bookingData', JSON.stringify(data));
    }
  }

  getBookingData() {
    return this.bookingData ? this.bookingData : JSON.parse(localStorage.getItem('bookingData'));
  }

}
