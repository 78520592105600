import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomAuthService} from './auth.service';
import {DataService} from './core/data.service';
import {AlertService} from './alert.service';
import {DOCUMENT} from '@angular/common';
import {environment} from '../environments/environment';

/**
 * This component is the landing page when user clicks login OR they click on a link requiring authentication
 * and are not logged in.  Redirects to Customer home/ Admin home if logged in ELSE AWS Cognito
 */
@Component({ template: ''})
export class LoginComponent implements OnInit {
    loggedIn = false;
    isAdmin = false;
    isOwner = false;
    submitted: boolean;
    cognitoUrl = environment.cognitoUrl;
    constructor(private _Router: ActivatedRoute,
                public authService: CustomAuthService,
                public router: Router,
                private dataService: DataService,
                private alertService: AlertService,
                @Inject(DOCUMENT) private document: Document) {
        this.alertService.clear();
        authService.loggedInStream$.subscribe(
            val => {
                this.loggedIn = val;
            });
        authService.isAdminStream$.subscribe(
            val => {
                this.isAdmin = val;
            });
        authService.isOwnerStream$.subscribe(
          val => {
              this.isOwner = val;
          });
    }

    // If user is not logged in then redirect to cognito otherwise direct to admin if is admin or customer
    ngOnInit(): void {

        const token = localStorage.getItem('cognito');
        if (token != null) {
            this.authenticateToken(token);
        } else {
            if (!this.loggedIn) {
                this._Router.fragment.subscribe(parameter => {
                    const ta = new URLSearchParams(parameter).get('id_token');
                    if ( ta == null ) {
                        this.document.location.href = this.cognitoUrl;
                    } else {
                        this.authenticateToken(ta);
                    }
                });
            } else {
                this.navigateAccordingly();
            }
        }
    }
    public navigateAccordingly() {
        if (this.isAdmin) {
            this.router.navigate(['admin']);
        } else {
            this.router.navigate(['customer/tiles']);
        }
    }

    private authenticateToken(token: string) {
        this.dataService.callGetAuthenticate('authenticate', token).subscribe(
            (details) => {
                this.authService.loginWithAuthorities(details);
                localStorage.setItem('cognito', token);
                this.navigateAccordingly();
            },
            (error) => {
                console.log('Failed to authenticate: ' + error);
                localStorage.removeItem('cognito');
                localStorage.removeItem('cognito');
                this.router.navigate(['']);
            });
    }
}
