import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
import { HIUser }     from './HIUser';
@Injectable()
export class UserService {
  // Observable string sources
  private activeUser = new Subject<HIUser>();
  // Observable string streams
  user$ = this.activeUser.asObservable();
  // Service message commands
  savedUser(user: HIUser) {
    this.activeUser.next(user);
  }
}
