import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { ImageUpload } from 'src/app/core/ImageUpload';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ImageUploadService {
    constructor(private http: HttpClient) {}
    private imageObservable = new Subject<ImageUpload>();
    image$ = this.imageObservable.asObservable();

  updateImage(image: ImageUpload) {
    this.uploadImage(image);
  }

  async uploadImage(image: ImageUpload) {
    const uploadUrl = 'https://l1ov8913l2.execute-api.eu-west-1.amazonaws.com/default/bookAPenUpload';
    const response = this.http.post(uploadUrl, image).toPromise().then(success => {
      console.log(success);
      image.savedFileName = (success as string).split('ID=')[1];
      this.imageObservable.next(image);
    }, err => { console.log(err); });
  }

  fetchPetImage(fileName: string): Promise<any> {
      console.log('fetching pet picture');
      const promise = new Promise((resolve, reject) => {
        const url = `https://0mkcqcv3dj.execute-api.eu-west-1.amazonaws.com/default/bookapenDownload?fileName=${fileName}`;
        this.http.get<string>(url).toPromise().then(res => {
            let dataArray: Iterable<number>;
            dataArray = res['Body']['data'];
            const array = Uint8Array.from(dataArray);
            resolve(this.convertImage(array));
         },
          err => {console.log('Failed to fetch', err); }); });
        return promise;
    }

    convertImage(imageData: Uint8Array): string {
      const b64encoded = btoa(imageData.reduce(function (data, byte) {
          return data + String.fromCharCode(byte);
        }, ''));
      return b64encoded;
    }
}
