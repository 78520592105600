 <div class="custom-card">
   <div class="card-header">
     <h3>Contact HollyIsland Kennels</h3>
   </div>
   <div class="card-body">
     <div *ngIf="details">
       {{ details }}
     </div>
     <div>
       <div>
         <label>Message: </label>
       </div>
       <div>
         <textarea [(ngModel)]="message" rows="10" cols="35" [disabled]="sending"></textarea>
       </div>
     </div>
     <p *ngIf="!sending">
       <button class="btn btn-primary" (click)="send()">Send</button>
       <button class="btn btn-primary" (click)="cancel()">Cancel</button>
     </p>
   </div>
</div>
