import { Component, OnInit }        from '@angular/core';
import { Router,
         NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DataService } from './core/data.service';

@Component({
  template: `
      <div class="login-form">
          <div class="container-t">
              <div class="form-wrapper">
                  <form class="form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                      <div class="heading">
                          <h2>Register</h2>
                          <h5>Please Register to continue</h5>
                      </div>
                      <div class="form-group">
                          <label for="firstName">Name</label>
                          <label for="firstName" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-sm">
                              <input id="firstName" type="text" formControlName="name" class="input-control">
                              <span class="tooltip-content">Name is required.</span>
                          </label>
                      </div>
                      <div class="form-group">
                          <label for="email">Email Address</label>
                          <label for="email" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-sm">
                              <input id="email" type="text" formControlName="email" class="input-control">
                              <span class="tooltip-content">Email Address is required.</span>
                          </label>
                      </div>
                      <div class="form-group">
                          <label for="password">Password</label>
                          <label for="password" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-sm">
                              <input id="password" type="text" formControlName="password" class="input-control">
                              <span class="tooltip-content">First Name is required.</span>
                          </label>
                      </div>
                      <div class="form-group">
                          <label for="passwordConf">Confirm Password</label>
                          <label for="passwordConf" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-sm">
                              <input id="passwordConf" type="text" formControlName="passwordConf" class="input-control">
                              <span class="tooltip-content">First Name is required.</span>
                          </label>
                      </div>

                      <button class="btn btn-primary"
                              type="submit"
                              [disabled]="registerForm.invalid">Register</button>
                  </form>
              </div>
          </div>
      </div>
     
`
})
export class RegisterComponent implements OnInit  {
  registerForm: UntypedFormGroup;
  submitted = false;
  constructor(private formBuilder: UntypedFormBuilder,
              public router: Router,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: '',
      email: '',
      password: '',
      passwordConf: ''
    });
  }

   onSubmit() {
     console.log('register pressed' + JSON.stringify(this.registerForm.getRawValue()));
     this.dataService.callPostNoAuth('register', JSON.stringify(this.registerForm.getRawValue())).subscribe(
       () => {
         console.log('done');
         },
       (error) => {
          console.log('Failed to register: ' + JSON.parse(error._body).message);});
    }


}
