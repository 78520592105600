
import {map, catchError, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import 'rxjs/Rx';
import {AddressResult} from './AddressResult';


@Injectable()
export class AddressService {
    constructor(private http: HttpClient) {
    }
    base = {'Accept': 'application/json', 'Content-Type': 'application/json'};
    public getAutoComplete(query: string): any {
        const options = {headers: this.base};
        console.log('Fetching autocomplete entries for ' + query);
        return this.http.get('https://api.getAddress.io/autocomplete/' + query + '?api-key=sn1-L5-8_ki0-RlY7xooKw30667' , options).pipe(
            map((response: Response) => {
                console.log(response);
                return response;
            }),
            catchError(this.handleError), );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            error.message);
    }

    getFullAddress(id) {
        const options = {headers: this.base};
        console.log('Fetching address for id ' + id);
        return this.http.get<AddressResult>('https://api.getAddress.io/get/' + id + '?api-key=sn1-L5-8_ki0-RlY7xooKw30667' , options).pipe(
          tap(_ => {
              localStorage.setItem('lastAddress', JSON.stringify(_));
          }),
          catchError(this.handleError), );
        // return this.http.get('https://api.getAddress.io/get/' + id + '?api-key=sn1-L5-8_ki0-RlY7xooKw30667' , options).pipe(
        //   map((response: Response) => {
        //       console.log(response);
        //       return response;
        //   }),
        //   catchError(this.handleError), );
    }

    getLastAddress() {
        return JSON.parse(localStorage.getItem('lastAddress'));
    }
}
