import {NgModule} from '@angular/core';
import {PetSelectorComponent} from './pet-selector/pet-selector.component';
import {BookingListComponent} from './booking-list/booking-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {UserListComponent} from './user-list/user-list.component';
import {CalanderViewComponent} from './calander-view/calander-view.component';
import {CalendarHeadComponent} from './calander-view/calander-head.component';
import {PetDetailComponent} from './pet-detail/pet-detail.component';
import {DayDetailComponent} from './day-detail/day-detail.component';
import { BookingStepperComponent } from './bookingSteps/booking-stepper.component';
import { ImageUploadComponent } from './file-upload/image-upload.component';
import { PipesModule } from './pipes/pipes.module';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { PetsComponent } from './pets/pets.component';
import {StepComponent} from './step/step.component';
import {BannerComponent} from './banner/banner.component';
import {StarsComponent} from './stars/stars.component';
import {CarouselComponent} from './carousel/carousel.component';

@NgModule({
    declarations: [
        PetSelectorComponent,
        BookingListComponent,
        UserListComponent,
        CalanderViewComponent,
        CalendarHeadComponent,
        PetDetailComponent,
        DayDetailComponent,
        BookingStepperComponent,
        ImageUploadComponent,
        UserDetailComponent,
        PetsComponent,
        StepComponent,
        BannerComponent,
        StarsComponent,
        CarouselComponent,
    ],
    exports: [
        PetSelectorComponent,
        BookingListComponent,
        UserListComponent,
        DragDropModule,
        CalanderViewComponent,
        CalendarHeadComponent,
        PetDetailComponent,
        DayDetailComponent,
        BookingStepperComponent,
        ImageUploadComponent,
        UserDetailComponent,
        PetsComponent,
        StepComponent,
        BannerComponent,
        StarsComponent,
        CarouselComponent,
    ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    RouterModule,
    DragDropModule,
    PipesModule
  ]
})
export class SharedModule {}
