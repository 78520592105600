import {ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../core/data.service';
import {KennelSummary} from '../core/KennelSummary';
import {Observable} from 'rxjs';
import {RestStateInterface} from '../core/RestStateInterface';
import {Page} from '../core/Page';
import {CustomAuthService} from '../auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../alert.service';
import {AnimationType} from '../shared/carousel/carousel.animations';
import {CarouselComponent} from '../shared/carousel/carousel.component';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AddressService} from '../core/address.service';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private ngZone: NgZone,
              private router: Router,
              public authService: CustomAuthService,
              private alertService: AlertService,
              private dataService: DataService,
              private formBuilder: UntypedFormBuilder,
              private addressService: AddressService,
              private cdr: ChangeDetectorRef,
              private _Router: ActivatedRoute) {
      this.contactForm = this.formBuilder.group({
          name: new UntypedFormControl('', Validators.required),
          email: new UntypedFormControl('', Validators.required),
          phonenumber: [null],
          comment: [null, Validators.required]
      });
  }

  dateIn: any;
  dateOut: any;
  showValidationErrors = false;
  showLocationError = false;
  fromDateError = '';
  toDateError = '';
  numCats: number;
  numDogs: number;
  numCatPens: number = null;
  numDogPens: number = null;
  maxDistance = 10;
  latitude: string;
  longitude: string;
  preSelectedNumCatPens: any;
  preSelectedNumDogPens: any;
  address = '';
  kennelResults: KennelSummary[];
  kennelResults$: Observable<Page<KennelSummary>>;
  selectedKennel: KennelSummary;
  animationType = AnimationType.Fade;

  searchControl: UntypedFormControl;
  contactForm: UntypedFormGroup;
  private debounce = 400;


  @ViewChild(CarouselComponent) carousel: CarouselComponent;
  @ViewChild('resultsTable') ResultsTable: ElementRef;
  searchResults: any;
  disableAutocomplete = false;
  displayKennelModal = false;
  ngOnInit() {
    const existingData = this.authService.getBookingData();
    if (existingData) {
      this.dateIn = existingData.dateIn;
      this.dateOut = existingData.dateOut;
      this.preSelectedNumCatPens = existingData.numCatPens;
      this.preSelectedNumDogPens = existingData.numDogPens;
      if (this._Router.snapshot.queryParams['referral'] === 'newkennel') {
        this.displayKennelModal = true;
      }

    }
    const lastAddress = this.addressService.getLastAddress();
    this.searchControl = new UntypedFormControl('');
    this.searchControl.valueChanges
      .pipe(debounceTime(this.debounce), distinctUntilChanged())
      .subscribe(query => {
        if (this.disableAutocomplete) {
          this.disableAutocomplete = false;
        } else if (query === '') {
          this.searchResults = [];
        } else {
          console.log(query);
          this.addressService.getAutoComplete(query).subscribe((it) => {
            console.log(it);
            this.searchResults = it.suggestions;
          }, (error) => {
            console.log(error);
          });
        }
      });
    this.cdr.detectChanges();
    if (lastAddress) {
        this.latitude = lastAddress.latitude;
        this.longitude = lastAddress.longitude;
        this.disableAutocomplete = true;
        this.searchControl.setValue(lastAddress.formatted_address.filter(Boolean).join(', '));
    }
  }

  findAddress(result: any) {
    console.log('Lookup address with id: ' + result.id);
    this.addressService.getFullAddress(result.id).subscribe((it) => {
      console.log(it);
      this.disableAutocomplete = true;
      this.searchControl.setValue(result.address);
      this.searchResults = [];
      this.latitude = it.latitude;
      this.longitude = it.longitude;

    }, (error) => {
      console.log(error);
    });
  }
  onDateChange(toFrom) {
    const current = new Date().getTime();
    if (toFrom === 1 || toFrom === 3) {
      const fromDate = new Date(this.dateIn).getTime();
      if (fromDate < current) {
          this.fromDateError = 'Please select From date in future';
          console.log('From date in the past.');
          this.showValidationErrors = true;
      } else {
          this.fromDateError = '';
      }
    }
    if (toFrom === 2 || toFrom === 3) {
        const fromDate = new Date(this.dateIn).getTime();
        const toDate = new Date(this.dateOut).getTime();
        if (toDate < fromDate) {
            this.toDateError = 'Please select To date greater than From date';
            this.showValidationErrors = true;
        } else {
            this.toDateError = '';
        }
    }
  }

  findKennels() {
    console.log('Search for availability');
    const state: RestStateInterface = {};
    this.showValidationErrors = false;
    state.filters = [];
    state.filters.push({'property': 'latatitude', 'value': this.latitude});
    state.filters.push({'property': 'longatude', 'value': this.longitude});
    state.filters.push({'property': 'maxDistance', 'value': this.maxDistance});
    if (!this.latitude || !this.longitude || !this.maxDistance) {
        this.showLocationError = true;
    } else {
        this.showLocationError = false;
    }
    if (this.numCatPens && this.numCatPens > 0) {
      state.filters.push({'property': 'catPens', 'value': this.numCatPens});
    }
    if (this.numDogPens && this.numDogPens > 0) {
      state.filters.push({'property': 'dogPens', 'value': this.numDogPens});
    }
    if ((!this.numCatPens || this.numCatPens === 0) && (!this.numDogPens || this.numDogPens === 0)) {
      console.log('Pens not selected');
        this.showValidationErrors = true;
    }
    if (!this.dateIn || !this.dateOut) {
        if (!this.dateIn) {
            this.fromDateError = 'Please select From date';
        }
        if (!this.dateOut) {
            this.toDateError = 'Please select To date';
        }
        console.log('Dates not selected');
        this.showValidationErrors = true;
    } else {
        this.onDateChange(3);
    }
    if (true === this.showValidationErrors) {
      console.log('validation errors');
        return;
    }
    state.filters.push({'property': 'dateIn', 'value': this.dateIn});
    state.filters.push({'property': 'dateOut', 'value': this.dateOut});
    this.kennelResults$ = this.dataService.callGetPaged('kennel/availability', state);
    this.kennelResults$.subscribe(
        x => {
          console.log('response from availability');
            this.kennelResults = x.content;
          this.ResultsTable.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          if (this.kennelResults.length === 0) {
            console.log('No kennels found'); // TODO message on screen
          }
        },
        error => {
          console.log(error);
        }
    );
  }

  selectKennel($event) {
    this.selectedKennel = $event;
    // todo remove dirty hack
    this.selectedKennel.images = ['https://www.southern-timber.co.uk/wp-content/uploads/2019/02/rowlinsons_dog_kennel_large.jpg',
        'https://images-na.ssl-images-amazon.com/images/I/813nW9YacfL._AC_SL1500_.jpg',
        'https://cdn.shopify.com/s/files/1/2327/5701/articles/Omega-3-For-Dogs_1200x.jpg?v=1561351694',
        // tslint:disable-next-line:max-line-length
        'https://img.webmd.com/dtmcms/live/webmd/consumer_assets/site_images/article_thumbnails/slideshows/surprises_about_dogs_and_cats_slideshow/1800x1200_surprises_about_dogs_and_cats_slideshow.jpg',
        'https://static.scientificamerican.com/sciam/cache/file/92E141F8-36E4-4331-BB2EE42AC8674DD3_source.jpg'];

  }
    public logInCustomer() {
        this.router.navigate(['login']);
    }


  //   public logOut() {
  //     this.authService.logout();
  //     this.router.navigate(['']);
  //     this.alertService.warn('You have been successfully logged out');
  // }

  public makeReservation () {
      const data = {
          dateIn: this.dateIn,
          dateOut: this.dateOut,
          numCatPens: this.numCatPens,
          numDogPens: this.numDogPens,
          selectedKennel: this.selectedKennel,
          latitude: this.latitude,
          longitude: this.longitude,
          address: this.address
      };
      this.authService.updateBookingData(data);
      console.log('Going to new booking');
      this.router.navigate(['customer/booking/new']);
  }

    sendContact() {
      this.dataService.sendContact(this.contactForm.value)
        .subscribe(
          () => {
            console.log('done');
            this.contactForm.reset();
            this.alertService.info('Message Sent.');
            window.scroll(0, 0);
          },
          (error) => {
            this.alertService.warn('Sorry - Failed to send message.');
            window.scroll(0, 0);
          });
    }
}
