import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import Amplify from 'aws-amplify';
// import Auth from '@aws-amplify/auth';
// import awsmobile from './aws-exports';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


// const isLocalhost = Boolean(
//     window.location.hostname === 'localhost' ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === '[::1]' ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(
//         /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//     )
// );
// Assuming you have two redirect URIs, and the first is for localhost and second is for production
// const [
//   localRedirectSignIn,
//   productionRedirectSignIn,
// ] = awsmobile.oauth.redirectSignIn.split(',');
//
// const [
//   localRedirectSignOut,
//   productionRedirectSignOut,
// ] = awsmobile.oauth.redirectSignOut.split(',');

// const updatedAwsConfig = {
//   ...awsmobile,
//   oauth: {
//     ...awsmobile.oauth,
//     redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
//     redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
//   }
// };


// Amplify.configure(updatedAwsConfig);

// Auth.configure(updatedAwsConfig
// //     {
// // // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
// //   identityPoolId: 'eu-west-1:5aa04047-49e2-4041-969c-cc687736ce15',
// //
// //   // REQUIRED - Amazon Cognito Region
// //   region: 'eu-west-1',
// //
// //
// //   // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
// //   userPoolWebClientId: 'a1b2c3d4e5f6g7h8i9j0k1l2m3',
// //
// //   // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
// //   mandatorySignIn: true,
// //
// //   // OPTIONAL - Configuration for cookie storage
// //   // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
// //   cookieStorage: {
// //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
// //     domain: '.yourdomain.com',
// //     // OPTIONAL - Cookie path
// //     path: '/',
// //     // OPTIONAL - Cookie expiration in days
// //     expires: 365,
// //     // OPTIONAL - Cookie secure flag
// //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
// //     secure: false,
// //   },
// //   federationTarget: 'COGNITO_USER_POOLS',
// //   oauth: {
// //     domain: 'bookapenfeda8b36dc-da8b36dc-dev.auth.eu-west-1.amazoncognito.com',
// //     scope: [
// //       'phone',
// //       'email',
// //       'openid',
// //       'profile',
// //       'aws.cognito.signin.user.admin'
// //     ],
// //     redirectSignIn: 'http://localhost:4200',
// //     redirectSignOut: 'http://localhost:4200',
// //     responseType: 'code'
// //
// //     // "oauth": {
// //     //   "domain": "bookapenfeda8b36dc-da8b36dc-dev.auth.eu-west-1.amazoncognito.com",
// //     // }
// //   }
// // }
// );



if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
