<div class="app-container">
    <banner [banner]="banner"></banner>
    <div class="container-t">


        <div *ngIf="!isHome && loggedIn" class="topBar">
            <ul>
                <li><a class="bookapen-link nav-link"  href="#" [routerLink]="['/']" [class.active]="router.url==='/'">BookAPen.com</a></li>
                <li class="nav-item"><a class="nav-link" href="#" [routerLink]="['/customer/tiles']" [class.active]="router.url==='/customer/tiles'">My Homepage</a></li>
                <li *ngIf="isAdmin" class="nav-item"><a class="nav-link" href="#" [routerLink]="['/admin']" [class.active]="router.url==='/admin'">Kennel Administration</a></li>
                <li *ngIf="isOwner" class="nav-item"><a class="nav-link" href="#" [routerLink]="['/admin/superuser']" [class.active]="router.url==='/admin/superuser'">System Administration</a></li>
            </ul>

            <div tabindex="0" class="menu">
                <div class="dropdown-c">
                    <span class="name">{{name | slice:0:30}}</span><img src={{userPic}} class="userPic">
                </div>
                <div class="menu-dropdown">
                    <a [routerLink]="['/customer/details']" >My Details</a>
                    <a [routerLink]="['/customer/pets']">My Pets</a>
                    <a [routerLink]="[{ outlets: { popup: ['contact'] } }]">Contact Site Admin</a>
                    <a (click)="logOut()">Log out</a>
                </div>
            </div>
        </div>
    </div>

  <div class="">
    <div class="" style="padding: 0">
      <router-outlet></router-outlet>
      <router-outlet name="popup"></router-outlet>
    </div>
  </div>
</div>
