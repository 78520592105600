
import {map, mergeMap, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Page} from './Page';
import {environment} from 'src/environments/environment';
import {RestStateInterface} from './RestStateInterface';
import {Router} from '@angular/router';
import 'rxjs-compat/add/operator/mergeMap';


@Injectable()
export class DataService {
    constructor(private http: HttpClient, private router: Router) {
    }

    baseUrl = environment.baseUrl;
    base = {'Accept': 'application/json', 'Content-Type': 'application/json'};
    msgBase = {'Accept': 'application/json', 'Content-Type': 'application/json', 'x-api-key': 'TkXd14tg5w7Sm7c7YWAke7bTSRFlrhw33vWpozN3'};

    public callGetPaged<T>(url: string, state: RestStateInterface = null): Observable<Page<T>> {
        const toApply: Map<string, any> = new Map<string, string>();
        const options = {headers: this.base, withCredentials: true};
        if (state) {
            if (state.page) {
                const pageNo = Math.floor(state.page.from / state.page.size);
                toApply.set('size', state.page.size);
                toApply.set('page', pageNo);
            }
            if (state.filters) {
                for (const filter of state.filters) {
                    const {property, value} = <{ property: string, value: string }>filter;
                    toApply.set(property, value);
                }
            }
            if (state.sort) {
                if (state.sort.reverse) {
                    toApply.set('sort', state.sort.by + ',desc');
                } else {
                    toApply.set('sort', state.sort.by);
                }
            }
        }
        url += '?';
        toApply.forEach((v: string, k: string) => {
            url += k + '=' + v + '&';
        });
        return this.http.get<Page<T>>(this.baseUrl + url, options).pipe(
          map((response: Page<T>) => {
              return response;
          }),
          catchError(error => this.handleError(error)), );
    }

    public callGet(url: string): any {
        const options = {headers: this.base, withCredentials: true};
        return this.http.get(this.baseUrl + url, options).pipe(
            map((response: Response) => {
                return response;
            }),
            catchError(error => this.handleError(error)), );
    }

    public callPostAndGet(url: string, body: string): any {
        return this.http.post(this.baseUrl + url, body, {
            headers: this.base,
            withCredentials: true,
            observe: 'response'
        }).mergeMap
            ((res: any) => {
                const location = res.headers.get('Location');
                return this.http.get(location, {headers: this.base, withCredentials: true});
            });
            // .map((res: Response) => res)
            // .catch(error => this.handleError(error));
    }

    public callPost(url: string, body: string): any {
        return this.http.post(this.baseUrl + url, body, {headers: this.base, withCredentials: true, observe: 'response'})
            .pipe(
                map(
                    response => {
                        return response;
                    },
                    error => {
                        console.log('Error:  ' + error);
                    }),
              catchError(error => this.handleError(error)));
    }

    public callPut(url: string, body: string): any {
        return this.http.put(this.baseUrl + url, body, {headers: this.base, withCredentials: true}).pipe(
            map(
                response => {
                    return response;
                },
                error => {
                    console.log('Error: ' + error);
                }));
    }

    public callGetAuthenticate(url: string, body: string): any {
        this.base['authorization'] = 'Bearer ' + body;

        const options = {headers: this.base, withCredentials: true};

        return this.http.get(this.baseUrl + url, options).pipe(
            map((response: Response) => {
                return response;
            }),
            catchError(error => this.handleError(error)), );
    }

    public callPostNoAuth(url: string, body: string): any {
        this.base['authorization'] = 'Basic ' + btoa(body + ':noPassword');
        return this.http.post(this.baseUrl + url, body, {headers: this.base}).pipe(
            map(
                response => {
                    return response;
                },
                error => {
                    console.log('Error:  ' + error);
                }));
    }


    public callDelete(url: string): any {
        return this.http.delete(this.baseUrl + url, {headers: this.base, withCredentials: true}).pipe(map(response => response, error => {
            console.log('Error: ' + error);
        }));
    }


    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
            if (error.status === 403) {
                // Navigate to home page.
                this.router.navigate(['/']);
            }
        }
        // return an observable with a user-facing error message
        return throwError(
            error.message);
    }

    sendContact(value: any) {
        return this.http.post(
          'https://xmvn4nt35l.execute-api.eu-west-1.amazonaws.com/default/bookapen_contact_lambda',
          // 'https://pule3l585l.execute-api.eu-west-1.amazonaws.com/default/bookapen_contact_lambda',
            JSON.stringify(value), {headers: this.msgBase}).pipe(
            map(
                response => {
                    return response;
                },
                error => {
                    console.log('Error:  ' + error);
                }));
    }
}
