import {Component, Input} from '@angular/core';
import {Banner} from './banner';
import {AlertService} from '../../alert.service';

@Component({
    selector: 'banner',
    templateUrl: 'banner.component.html',
    styleUrls: ['banner.component.scss']
})
export class BannerComponent {
    constructor(private alertService: AlertService) {
    }
    banner_: Banner = { success: false, message: '' };
    lastSeen: number;
    @Input() set banner (banner: Banner) {
        this.banner_ = banner;
        const now = Date.now();
        this.lastSeen = now;

        setTimeout(() => {
            this.alertService.clear();
        }, 30000);
    }
}
