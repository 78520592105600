import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Banner} from './shared/banner/banner';

@Injectable()
export class AlertService {
  private _banner: Banner = {message: '', success: false};
  public banner = new BehaviorSubject<Banner>(this._banner);
  bannerStream$ = this.banner.asObservable();

  constructor () {}
  warn(msg: string): void {
    this._banner.message = msg;
    this._banner.success = false;
    this.banner.next(this._banner);
  }
  info(msg: string): void {
    this._banner.message = msg;
    this._banner.success = true;
    this.banner.next(this._banner);
  }
  clear(): void {
    this._banner.message = '';
    this._banner.success = false;
    this.banner.next(this._banner);
  }
}
