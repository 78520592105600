import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { CustomAuthService } from './auth.service';
import {Banner} from './shared/banner/banner';
import {AlertService} from './alert.service';

@Component({
    selector: 'my-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    banner: Banner;
    loggedIn = false;
    isHome = true;
    isAdmin = false;
    isOwner = false;
    name = 'Welcome Back!';
    userPic = 'https://icon-library.net/images/default-user-icon/default-user-icon-4.jpg';

    constructor(public router: Router, public authService: CustomAuthService, private alertService: AlertService) {

        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                this.checkHome();
            }
        });
        authService.loggedInStream$.subscribe(
          val => {
              this.loggedIn = val;
          });
        authService.isAdminStream$.subscribe(
            val => {
                this.isAdmin = val;
            });
        authService.isOwnerStream$.subscribe(
            val => {
                this.isOwner = val;
            });
        authService.userNameStream$.subscribe(
            val => {
                this.name = val;
            });
        authService.userPicStream$.subscribe(
            val => {
                if (val) {
                    this.userPic = val;
                }
            });
        alertService.bannerStream$.subscribe(
            val => {
                this.banner = val;
            });
    }

    checkHome() {
        const urlSplitted = window.location.href.split('/');
        this.isHome = urlSplitted.length === 4 && (urlSplitted[3] === '' || urlSplitted[3].startsWith('?'));
    }
    public logOut() {
        this.authService.logout();
        this.router.navigate(['']);
        this.alertService.warn('You have been successfully logged out');
    }
}
