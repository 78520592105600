import {Component, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'stars-component',
    templateUrl: 'stars.component.html',
    styleUrls: ['stars.component.scss']
})
export class StarsComponent {

    constructor(public sanitizer: DomSanitizer) {
    }
    @Input()
    rating: number;

}
