import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { SanatizeHtml } from './SanatizeHtml';

@NgModule({
  declarations: [SanatizeHtml],
  imports: [CommonModule],
  exports: [SanatizeHtml]
})

export class PipesModule {}
