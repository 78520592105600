<div class="modal" *ngIf="selectedKennel">
  <div class="overlay"></div>
  <div class="modal_content">
    <!-- Dynamic Section -->
    <!-- Pictures to be added....-->
    <div class="container">
      <div class="kennel-modal">
        <carousel [slides]="selectedKennel.images" [animationType]="animationType"></carousel>
        <div id="kennel_info">
          <h2 id="kennel_name">{{selectedKennel.name}}</h2>
          <div class="form-group">
            <label class="customer-form-label">Kennel Description:</label>
            <p id="kennel_description">{{selectedKennel.description}}</p>
          </div>

          <div class="form-group">
            <label class="customer-form-label">Address:</label>
            <p id="kennel_address">{{selectedKennel.address}}</p>
          </div>

          <div class="form-group">
            <label class="customer-form-label">Rating:</label>
            <p id="kennel_rating">
              <span *ngIf="!selectedKennel.rating">No reviews yet</span>
              <span *ngIf="selectedKennel.rating">{{selectedKennel.rating}} Stars</span></p>
          </div>

          <div>
            <button title="Make Reservation" class="btn btn-primary" (click)="makeReservation()">
              Make Reservation
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Dynamic Section -->
    <button title="Close" class="close_modal" (click)="selectedKennel=null;">
      X
    </button>
  </div>
</div>
<div class="modal" *ngIf="displayKennelModal">
  <div class="overlay"></div>
  <div class="modal_content">
    <!-- Dynamic Section -->
    <!-- Pictures to be added....-->
    <div class="container">
      <div class="kennel-modal">
        <h2>BookAPen.com</h2>
        <div>We are currently accepting kennels from the UK only however this will expand shortly.</div>
        <div>As we grow we will advertise to and attract customers looking for kennel accommodation, as a registered kennel you will gain free exposure to new customers.</div>
        <div>The site is free to use and free from advertisements. As a Kennel owner you can use this site
        to manage your kennels and bookings and greatly reduce your paperwork</div>
        <div>In future we will add support for online payments, record keeping, online reviews and much much more!</div>
        <div>Because we are new and there will be many changes as we grow early customers will get full access to this site free of charge. In addition we want your feedback, if there is something missing that you would like to see just ask and we will be happy to help!</div>
        <div>If you wish to get involved please use the contact form to reach out and we will get you onboarded soon!</div>
      </div>
    </div>
    <!-- End of Dynamic Section -->
    <button title="Close" class="close_modal" (click)="displayKennelModal=false;">
      X
    </button>
  </div>
</div>

<div id="masthead">
  <div class="container-t">
    <div class="top-bar">
      <img src="assets/bookApen-log2.png" alt="" class="logo">
      <a class="loginbutton" [routerLink]="" (click)="logInCustomer()">LOGIN</a>
    </div>
  </div>
</div>
<div class="masthead-background">
  <div id="locater">
    <div class="locater-box">
      <form id="search">
        <div class="input"><label for="address">Enter your address</label>

          <input id="address" type="text" class="aInputBox" placeholder="Begin Typing to Search" [formControl]="searchControl"  autocomplete="off"/>
          <ul class="addressDropdown">
            <li *ngFor="let result of searchResults" class="hover-r" (click)="findAddress(result)">{{result.address}}</li>
          </ul>
        </div>
        <span *ngIf="showLocationError" class="error-span">
            Please type a valid address
        </span>
        <div class="input distance"><label for="range">Kennel distance <span id="readout">{{maxDistance}}</span> (miles)</label>
          <input id="range" class="slider" type="range" min="5" max="100" step="5" [(ngModel)]="maxDistance" name="range"></div>
        <div class="input">
          <label for="date_in">From</label>
          <input type="date" [(ngModel)]="dateIn" (change)="onDateChange(1)" name="date_in" id="date_in" placeholder="dd/mm/yyyy" pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)" required>
          <span class="error-span">
            {{fromDateError}}
          </span>
        </div>
        <div class="input">
          <label for="date_out">To</label>
          <input type="date" [(ngModel)]="dateOut" (change)="onDateChange(2)" name="date_out" id="date_out" placeholder="dd/mm/yyyy" pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)" required>
          <span class="error-span">
            {{toDateError}}
          </span>
        </div>
        <div class="input">
          <div class="drop">
            <label for="dogs" id="dogs-label" class="tooltipField">Dogs
              <span class="tooltiptext">Select number of Pens required</span>
              <select id="dogs" name="dogs" [(ngModel)]="numDogPens" required>
                <option [ngValue]="null">Select Dogs</option>
                <option *ngFor="let n of [1, 2, 3, 4, 5, 6]" [ngValue]=n>{{n}}</option>
              </select></label>
            <label for="cats" id="cats-label" class="tooltipField">Cats
              <span class="tooltiptext">Select number of Pens required</span>
              <select id="cats" name="cats" [(ngModel)]="numCatPens" required>
                <option [ngValue]="null">Select Cats</option>
                <option *ngFor="let n of [1, 2, 3, 4, 5, 6]" [ngValue]=n>{{n}}</option>
              </select></label>
          </div>
          <span *ngIf="(!numDogPens || numDogPens === 0) && (!numCatPens || numCatPens === 0) &&  showValidationErrors" class="error-span">
            Please select at least one pet.
          </span>
        </div>
        <input id="searchBtn" type="submit" value="Search availability" (click)="findKennels()">
      </form>
    </div>
  </div>
</div>

<div #resultsTable id="resultsTable">
  <div class="container-t">
    <div *ngIf="kennelResults && kennelResults.length > 0" class="resultsBlock">
      <h3>Select a result</h3>
      <table>
        <tr>
          <th>Business</th>
          <th>Distance</th>
          <th>Rating</th>
        </tr>
        <tr *ngFor="let result of kennelResults" class="kennel" (click)="selectKennel(result)">
          <td>{{result.name}}</td>
          <td>{{result.distance}} Miles</td>
          <td><stars-component [rating]="0"></stars-component>Not Rated</td>
        </tr>
      </table>
    </div>
    <div *ngIf="kennelResults && kennelResults.length == 0" class="resultsBlock">
      <h4>No kennels Found, if your preferred kennel is not yet registered with this site please tell them to get in touch!</h4>
    </div>
  </div>

</div>
<div>
  <section id="intro">
    <div class="container-t">
      <div class="intro-wrapper">
        <div class="intro-img">
          <img src="assets/intro.jpg" alt="">
        </div>
        <div class="intro-content">
          <h1>Pet boarding facilities</h1>
          <ul>
            <li>Check availability for pet boarding locally and book online.</li>
            <li>Book your pets into any of our subscribed kennels - more and more are joining all the time!</li>
            <li>Enter details for you and your pets once - saving time on check-in.</li>
            <li>Upload and store photographs and vaccination records securely under your account and shared electronically with kennels.</li>
            <li class="kennel-owner-link">Are you a Kennel Owner? If so <a (click)="displayKennelModal=true">CLICK HERE</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</div>
<section class="contact-div">
  <div class="container-t">
    <div class="form-wrapper">
      <div class="form-container">
        <div class="contact-logo">
          <img src="assets/letter-icon.png" alt="">
        </div>
        <h2>Contact Us</h2>

        <form [formGroup]="contactForm" class="">
          <div class="form-group">
            <label for="name" class="">Name:</label>
            <input class="input-control" id="name" type="text" formControlName="name" placeholder="Required">
          </div>
          <div class="form-group errorMessage" *ngIf="contactForm.controls['name'].invalid && (contactForm.controls['name'].dirty || contactForm.controls['name'].touched)">
            <div *ngIf="contactForm.controls['name'].errors.required">
              Name is required.
            </div>
          </div>
          <div class="form-group">
            <label for="email" class="customer-form-label">Email:</label>
            <input class=" input-control" type="text" id="email" formControlName="email" placeholder="Required">
          </div>
          <div class="form-group errorMessage" *ngIf="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched)">
            <div *ngIf="contactForm.controls['email'].errors.required">
              Email address is required.
            </div>
          </div>
          <div class="form-group">
            <label for="number" class="customer-form-label">Phone Number:</label>
            <input class=" input-control" type="text" id="number" formControlName="phonenumber">
          </div>
          <div class="form-group">
            <label for="comment" class="customer-form-label">Comments:</label>
            <textarea class=" input-control" id="comment" formControlName="comment" placeholder="Required"></textarea>
          </div>
          <div class="form-group errorMessage" *ngIf="contactForm.controls['comment'].invalid && (contactForm.controls['comment'].dirty || contactForm.controls['comment'].touched)">
            <div *ngIf="contactForm.controls['comment'].errors.required">
              Message is required.
            </div>
          </div>
          <div class="buttons-container">
            <button [disabled]="contactForm.pristine || contactForm.invalid" type="button" class="btn btn-primary" (click)="sendContact()">Submit</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</section>
<footer>

  <section>
    <div class="container-t">
      <div class="footer-wrapper">

        <div class="copyright"><p>2021  © Copyright BookAPen</p></div>
        <div class="footer-links">
        </div>
      </div>
    </div>
  </section>

</footer>



